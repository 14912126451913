import React from 'react'
import PropTypes from 'prop-types'
import './all.sass'

export const HTMLContent = ({ content }) => (
  <div className="Blogcontent" dangerouslySetInnerHTML={{ __html: content }} />
)

const Content = ({ content }) => (
  <div className="Blogcontent">{content}</div>
)

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
}

HTMLContent.propTypes = Content.propTypes

export default Content
